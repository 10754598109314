"use strict";

$(document).ready(function () {
  $(function () {
    var menu = $('.navbar-collapse');
    var header = $('.header');
    var toggler = $('.js-toggler');
    toggler.on('click', function () {
      if (toggler.hasClass('opened')) {
        hideMenu();
      } else {
        showMenu();
      }
    });

    function hideMenu() {
      toggler.removeClass('opened');

      if (!window.pageYOffset > 32) {
        header.removeClass('opened');
      }
    }

    function showMenu() {
      header.addClass('opened');
      toggler.addClass('opened');
    } // On wp


    var wpAdmin = $(document).find('#wpadminbar');

    if (wpAdmin.length !== 0) {
      header.classList.add('logged-admin');
    }

    var heroSection = $('.hero');
    var userAgent = window.navigator.userAgent.toLowerCase();

    function heroMove() {
      heroSection.on('mousemove', function (e) {
        var THRESHOLD = 15;
        var clientX = e.clientX,
            clientY = e.clientY,
            currentTarget = e.currentTarget;
        var clientWidth = currentTarget.clientWidth,
            clientHeight = currentTarget.clientHeight,
            offsetLeft = currentTarget.offsetLeft,
            offsetTop = currentTarget.offsetTop;
        var horizontal = (clientX - offsetLeft) / clientWidth;
        var vertical = (clientY - offsetTop) / clientHeight;
        var rotateX = (THRESHOLD / 2 - horizontal * THRESHOLD).toFixed(2);
        var rotateY = (vertical * THRESHOLD - THRESHOLD / 2).toFixed(2);
        heroSection.find('.section-text').css({
          "transform": "translate(".concat((e.clientX - $(window).width() / 2) / 36, "px, ").concat((e.clientY - $(window).height() / 2) / 36, "px)"),
          "-moz-transform": "translate(".concat((e.clientX - $(window).width() / 2) / 36, "px, ").concat((e.clientY - $(window).height() / 2) / 36, "px)"),
          "transition": ".1s",
          "-moz-transition": ".1s"
        });
        $('.hero-map').css({
          "transform": "rotateX(".concat(rotateY * 2, "deg) rotateY(").concat(rotateX * 2, "deg)"),
          "-moz-transform": "rotateX(".concat(rotateY * 2, "deg) rotateY(").concat(rotateX * 2, "deg)")
        });
        setTimeout(function () {
          $('.hero-light').css({
            "transform": "translate(".concat(e.clientX - $('.hero-light').width() / 2, "px, ").concat(e.clientY - $('.hero-light').height() / 2, "px)"),
            "-moz-transform": "translate(".concat(e.clientX - $('.hero-light').width() / 2, "px, ").concat(e.clientY - $('.hero-light').height() / 2, "px)")
          });
        }, 100);
      });
      heroSection.on('mouseleave', function (e) {
        heroSection.find('.section-text').css({
          "transform": "translate(0px, 0px)",
          "-moz-transform": "translate(0px, 0px)",
          "transition": '.6s',
          "-moz-transition": '.6s'
        });
      });
    }

    setTimeout(function () {
      if ($(window).width() > 992) {
        heroMove();
      }

      $('.hero-map img').css({
        'animation': 'none'
      });
    }, 2000);

    function mouseHover(elem) {
      elem.on('mouseenter', function (e) {
        $(e.target).css({
          '--x': "".concat(e.offsetX || 0, "px"),
          '--y': "".concat(e.offsetY || 0, "px"),
          '--w': "".concat(e.target.clientWidth * 2.5, "px")
        });
      });
      elem.on('mouseleave', function (e) {
        $(e.target).css({
          '--x': "".concat(e.offsetX > e.target.clientWidth ? e.offsetX - 50 : e.offsetX + 50, "px"),
          '--y': "".concat(e.offsetY > e.target.clientHeight ? e.offsetY + 50 : e.offsetY - 50, "px")
        });
      });
    }

    mouseHover($('.btn'));
    mouseHover($('.solutions-slider-item'));
    mouseHover($('.choose-plan-table-item'));

    var mouseMoveBcg = function mouseMoveBcg(element) {
      var THRESHOLD = 15;
      element.on('mousemove', function (e) {
        var clientX = e.clientX,
            clientY = e.clientY,
            currentTarget = e.currentTarget;
        var clientWidth = currentTarget.clientWidth,
            clientHeight = currentTarget.clientHeight,
            offsetLeft = currentTarget.offsetLeft,
            offsetTop = currentTarget.offsetTop;
        var horizontal = (clientX - offsetLeft) / clientWidth;
        var vertical = (clientY - offsetTop) / clientHeight;
        var rotateX = (THRESHOLD / 2 - horizontal * THRESHOLD).toFixed(2);
        var rotateY = (vertical * THRESHOLD - THRESHOLD / 2).toFixed(2);
        element.css({
          "transform": "perspective(".concat(clientWidth * 2, "px) rotateX(").concat(rotateY, "deg) rotateY(").concat(rotateX / 2, "deg)")
        });
      });
      element.on('mouseleave', function (e) {
        element.css({
          "transform": "rotateX(0deg) rotateY(0}deg)"
        });
      });
    };

    var motionMatchMedia = window.matchMedia("(prefers-reduced-motion)");

    if (!motionMatchMedia.matches) {
      mouseMoveBcg($('.get-bcg'));
      mouseMoveBcg($('.work-bcg'));
    }

    var counter = $('.peoples-count');

    function animateCounter() {
      counter.each(function () {
        $(this).prop('Counter', 0).animate({
          Counter: $(this).data('value')
        }, {
          duration: 1500,
          easing: 'swing',
          step: function step(now) {
            $(this).text(Math.ceil(now));
            $(this).text($(this).text().substr(0, 2) + ',' + $(this).text().substr(2));
          }
        });
      });
    }

    var counterFlag = true;
    window.addEventListener('scroll', function () {
      if (elementIsVisible(counter) && counterFlag) {
        animateCounter();
        counterFlag = false;
      }
    });
    $('.choose-plan-head-tarif-item').on('click', function (e) {
      if (!$(e.target).hasClass('current')) {
        var table = $('.choose-plan-table');
        var tarifBcg = $('.choose-plan-head-tarif-bcg');

        if (tarifBcg.hasClass('yearly')) {
          tarifBcg.removeClass('yearly');
          tarifBcg.addClass('monthly');
        } else {
          tarifBcg.removeClass('monthly');
          tarifBcg.addClass('yearly');
        }

        $('.choose-plan-head-tarif-item').removeClass('current');
        $(e.target).addClass('current');
        table.map(function (i, item) {
          if ($(item).attr('data-plan') == $(e.target).attr('data-plan')) {
            $(item).addClass('current');
          } else {
            $(item).removeClass('current');
          }
        });
      }
    });
    /* SLIDERS SETTINGS */

    function initSolutions() {
      $('.js-solutions-slider').slick({
        slidesToShow: 5,
        slidesToScroll: 2,
        arrows: false,
        autoplay: true,
        dots: false,
        infinity: true,
        pauseOnHover: false,
        pauseOnFocus: false,
        variableWidth: true,
        responsive: [{
          breakpoint: 992,
          settings: {
            slidesToShow: 3
          }
        }, {
          breakpoint: 992,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }]
      });
    }

    var solutionSlider = $('.js-solutions-slider');
    var solutionSliderFlag = true;
    $(window).on('scroll', function () {
      if (elementIsVisible(solutionSlider, 200) && solutionSliderFlag) {
        initSolutions();
        solutionSlider.addClass('visible');
        solutionSliderFlag = false;
      }
    });
    $(window).on('scroll', function (e) {
      var heroEllipse = $('.hero-pictures-ellipse'),
          getSection = $('.get'),
          getSectionFlag = true;

      if (elementIsVisible(heroEllipse)) {
        $('.hero .section-text').css({
          'top': "-".concat(e.currentTarget.pageYOffset / 2, "px")
        });
      }

      if (elementIsVisible(getSection, 300) && getSectionFlag) {
        getSection.addClass('visible');
        getSectionFlag = false;
      }

      if (window.pageYOffset > 32) {
        header.addClass('opened');
      } else if (!toggler.hasClass('opened')) {
        header.removeClass('opened');
      }
    });
  });
});